import React from 'react'
import dynamic from 'next/dynamic'
import { PLP_Items_Per_Page } from '@/data/constants/PLP_Items_Per_Page'
import type { BooleanFacet, FacetsType } from '@/types/ElasticSearch/SearchAPIResponse'
import type { onFilterChangeHandler } from '@csc/dls'
import { error } from '@/services/Log'

const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const FilterIcon = dynamic(import('@csc/dls/Icons/Filter'))

const Select = dynamic(import('@csc/dls/Select'))
const ReadyToShipToggle = dynamic(import('./ReadyToShipToggle'))

interface FilterBarProps {
  filterOpen: boolean,
  totalNumberOfProducts: number,
  facets: FacetsType[],
  toggleFilterOpen: () => void,
  onFilterSelected: onFilterChangeHandler,
  itemsPerPage: number
  setItemsPerPage: (items: number) => Promise<void>
}
const FilterBar: React.FC<FilterBarProps> = ({
  filterOpen,
  toggleFilterOpen,
  totalNumberOfProducts,
  facets,
  onFilterSelected,
  itemsPerPage = PLP_Items_Per_Page,
  setItemsPerPage = () => { },
}) => {
  const readyToShipFacet = facets.find((f) => (
    f.type === 'BOOL'
    && f.machineName === 'readyToShip'
  ))
  return (
    <div className="z-10 md:sticky top-0 bg-white">
      <div className="md:border-t md:border-b items-center flex flex-col md:flex-row">
        <div className="py-4 border-t border-b md:border-0 flex items-center w-full md:w-auto justify-between md:justify-start">
          <button type="button" className="cursor-pointer" onClick={toggleFilterOpen}>
            <Text
              id="openCloseFilterDesktop"
              data-testid="openCloseFilterDesktop"
              className="mr-6 cursor-pointer hidden items-center md:flex"
            >
              <IconRenderer IconComponent={FilterIcon} className="mr-2" />
              {filterOpen ? 'Close Filter' : 'Filter & Sort'}
            </Text>
            <Text
              data-testid="openCloseFilterMobile"
              className="mr-6 cursor-pointer flex items-center md:hidden"
            >
              <IconRenderer IconComponent={FilterIcon} className="mr-2" />
              {' '}
              Filter & Sort
            </Text>
          </button>
          <div>
            {readyToShipFacet && (
              <ReadyToShipToggle
                onFilterSelected={onFilterSelected}
                filter={(readyToShipFacet as BooleanFacet)}
              />
            )}
          </div>
        </div>
        <Text bold className="pt-4 md:p-0 md:ml-auto text-gray-600 md:mr-8">
          Results (
          {totalNumberOfProducts}
          )
        </Text>
        <div className="hidden md:flex w-52 max-w-xs flex-row items-center">
          <Text className="min-w-fit pt-4 md:p-0 md:ml-auto md:mr-4">Per page:</Text>
          <Select
            id="Pagination_Select"
            options={['60 items', '120 items']}
            value={`${itemsPerPage} items`}
            onChange={(newValue) => Promise.resolve(setItemsPerPage(parseInt(`${newValue}`))).catch(error)}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterBar
